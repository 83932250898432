@import './base/reset';
@import './utilities/variables';
@import './base/global';
@import './modules/scrollbar';
@import './modules/tables';
@import './modules/inputs';
@import './modules/buttons';
@import './modules/titles';
@import './modules/images';
@import './modules/grid';
@import './modules/modals';

.cursor {
  --r: 0deg;
  position: fixed;
  left: -8px;
  top: -6px;
  pointer-events: none;
  user-select: none;
  display: none;
  transform: translate(var(--x), var(--y));
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, .4));
  svg {
      display: block;
      width: 28px;
      height: 28px;
      transform: rotate(var(--r));
  }
}
.loader {
  width: 25px;
  height: 25px;
  border: 5px solid #FFF;
  border-bottom-color: #6b6b6b;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader-button {
  width: 12px;
  height: 12px;
  border: 3px solid #FFF;
  border-bottom-color: #6b6b6b;
}

.selected-date {
  -webkit-box-shadow:inset 0px 0px 0px 2px #df002d;
  -moz-box-shadow:inset 0px 0px 0px 2px #df002d;
  box-shadow:inset 0px 0px 0px 2px #df002d;
}
.post-group .schedule-form {
  border: 1px dashed gray;
}
.schedule-form {
  // border: 1px solid gray;
  .action-bar {
    display: flex;
    flex-direction: row;
    button {
      background: none;
      img {
        max-height: 50px;
      }
    }
    i.fa {
      font-size: 32px;
      margin: 10px 15px;
      cursor: pointer;
    }
  }
  .footer-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    .span-time {
      margin-right: 10px;
    }
    button {
      background: none;
      img {
        max-height: 50px;
      }
    }
    i.fa {
      font-size: 32px;
      margin: 10px 15px;
      cursor: pointer;
    }
    .schedule-button {
      background-color: #8cd05b;
      color: #FFF;
      margin: 5px 0px;
      text-transform: uppercase;
    }
  }
  textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    border: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .social-media-form {
    margin: 0px 10px 7px 10px;
    .social-link {
      background-color: #e1e1e1;
      padding: 0.5em;
      border-radius: 2em;
      margin: 0.2em;
      display: inline-block;
    }
  }
}
.generate-image-form {
  .inline-dropdown {
    app-dropdown {
      top: 0px;
    }
  }
}
.image-list {
  margin: 10px 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Razmak između slika */

  .image-wrapper {
    position: relative;
  }

  .image-wrapper img {
    width:128px;
    height: 128px;
    object-fit: cover;
  }

  .fa-times {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
.gray-text {
  color: #959494;
}
@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
