input {
    border: none;
    border-bottom: 1px solid $main-color;
    color: $main-color;
    background-color: transparent;
    &:focus {
        border: none;
        border-bottom: 1px solid $main-color;
        outline: none;
    }
}
.inline-input {
  display: flex;
}
.textarea {
  border: none;
  resize: none;
  width: 100%;
  color: $main-color;
  background-color: transparent;
  padding: 5px 5px;
  &:focus {
      border: none;
      outline: none;
  }
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $main-color;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $main-color;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $main-color;
}

textarea::-webkit-input-placeholder, .post-title::placeholder {
  color:  gray;
  // font-style: italic;
}

.color-dropdown {
  .dropdown-selected {
    min-width: 102px;
  }
  input {
    margin-left: 10px;
    width: 80px;
  }
}


.writing-dd {
  display: inline-block !important;
  width: fit-available;
  .dropdown-options {
    li {
      padding: 5px 10px !important;
      line-height: normal !important;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 17px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #8cd05b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #8cd05b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}
