button {
    border: none;
    color: #fff;
    background-color: #8cd05b;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    &:hover {
        background-color: #70a749;
    }
}
.inverted-button {
    color: $main-color;
    background-color: $background-color;
    &:hover {
        background-color: $main-color;
        color: #fff;
    }
}
.link {
    color: $main-color;
    border-bottom: 1px solid $main-color;
    cursor: pointer;
    &:hover {
        border-bottom: none;
    }
}
.inverted-link {
    color: $main-color;
    cursor: pointer;
    border-bottom: none;
    &:hover {
        border-bottom: 1px solid $main-color;
    }
}
a {
    text-decoration: none;
}

a:visited {
    color: $main-color;
}
.cursor-pointer {
    cursor: pointer;
}
