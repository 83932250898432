::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $background-color;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: $background-color;
}

::-webkit-scrollbar-thumb {
    background-color: $main-color;
    background-image: -webkit-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.5) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0.5) 75%,
        transparent 75%,
        transparent
    );
}
