#social-media-modal {
  .modal-body {
    width: 250px;
    .social-media-header {
      margin-bottom: 10px;
      label {
        font-weight: bold;
      }
    }
    .social-media-item {
      display: flex;
      justify-content: space-between;
      input[type="checbox"] {
        margin-right: 10px;
      }
    }
  }
}
#generate-image-modal {
  .app-modal-body {
    min-width: 400px;
    // min-height: 400px;
  }
}
#link-modal, #schedule-date-modal {
  .app-modal-body {
    min-width: 400px;
    min-height: 200px;
    input {
      width: 100%;
    }
  }
}
#tags-modal {
  .link {
    margin-right: 10px;
  }
}
#notifications-modal {
  .app-modal {

    min-width: 700px;
    max-width: 100%;
  }
  .app-modal-body {
    min-width: 700px;
    max-width: 100%;
    // min-height: 400px;
  }
}

#credential-modal {
  .app-modal {
    min-width: 350px;
    max-width: 100%;
  }
  .app-modal-body {
    min-width: 350px;
    max-width: 100%;
    .form-group {
      display: flex;
    }
    .form-group >* {
      flex: 1 1 0px;
    }
    label {
      margin-right: 5px;
    }
    label, input {
      // display: block;
    }
  }
}
