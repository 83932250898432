* {
    font-family: "Lato", sans-serif;
    font-size: 1rem;
}
.container {
    margin: 0 auto;
    padding: 0;
    max-width: $container-max-size;
    width: 100vw;
    clear: both;
}
.main-container {
    margin: 0 auto;
    padding: 0;
    max-width: 1350px;
    width: 100vw;
    clear: both;
}
.float-right {
    float: right !important;
}
.float-left {
    float: left !important;
}
.nowrap {
    white-space: nowrap;
}
.margin-bottom-10 {
    margin-bottom: 10px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}
.margin-bottom-50 {
    margin-bottom: 50px;
}
.margin-top-10 {
    margin-top: 10px;
}
.margin-top-20 {
    margin-top: 20px;
}
.margin-top-30 {
    margin-top: 30px;
}
.margin-top-50 {
    margin-top: 50px;
}
.margin-left-5 {
  margin-left: 5px;
}
sup {
    vertical-align: super;
    font-size: smaller;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right;
}
.bold {
  font-weight: bold;
}
.hidden {
  visibility: hidden;
}
.clear-both {
  clear: both;
}
.mod-display-inline-block {
  display: inline-block;
}

.motivator-tab app-dropdown {
  .dropdown-wrapper {
    margin: 0.5em auto;
    align-items: center;
    .box {
       justify-content: center;
    }
    .dropdown-selected {
      width: auto !important;
    }
  }
}

.page-editor {
  textarea {
    width: 100%;
    height: 400px;
  }
}
.box-data {
  border: 1px dashed $main-color;
  padding: 5px;
  background-color: #f2f2f2;
  .box-title {
    font-weight: 600;
    padding-top: 30px;
    display: block;
  }
  .box-body {
    margin-bottom: 30px;
  }
}

.flow-header {
  display: flex;
  margin: 5px 0;
  .dropdown-wrapper .dropdown-selected {
    margin-left: 5px;
    margin-right: 5px;
    height: auto !important;
  }
  a {
    margin-left: 3px;
  }
  .add-new-post {
    cursor: pointer;
  }
}

.inline-dropdown {
  margin-top: 10px;
  app-dropdown {
    display: inline-block;
    position: relative;
    bottom: 2px;
  }
}
[hidden], .is-hidden {
  display: none !important;
}
