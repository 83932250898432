.explanation-header {
    border-top: 1px dashed $main-color;
    padding: 8px 0px;
    a {
      margin-left: 10px;
    }
}
.explanation-section {
    padding: 8px 0px;
    border-top: 1px dashed $main-color;
    border-bottom: 1px dashed $main-color;
    margin: 5px 0px;
    p {
        margin-top: 10px;
    }
}
