.table-header {
  color: $main-color;
  font-size: 14.666666666666666px;
  text-align: left;
  font-weight: bold;
  padding: 5px;
  border-top: 1px solid $main-color;
  border-bottom: 1px solid $main-color;
  margin-bottom: 30px;
  background-color: $background-color;
}
.table {
  color: $main-color;
  font-size: 14.666666666666666px;
  border-bottom: 1px solid $main-color;
  max-width: 1350px;
  overflow-x: auto;
  display: block;
  tbody {
    display: table;
    width: 100%;
  }
  .thead-row {
    background: #fff;
    border-top: 1px solid $main-color;
    border-bottom: 1px solid $main-color;
    th {
      padding: 3px 13px;
      text-align: left;
    }
  }
  .first-col {
    font-weight: bold;
  }
  td {
    // border: 1px solid #5f497a;
    padding: 3px 13px;
    span.option {
      cursor: pointer;
    }
    span.selected {
      text-decoration: underline;
    }
  }
  tr:nth-child(even) {
    background-color: $background-color;
  }
  tfoot {
    td {
      padding-top: 10px;
      font-weight: bold;
    }
  }
}
.display-table {
  display: table;
}
